document.addEventListener('DOMContentLoaded', () => {
  FAQTabsHandler();
});
//

//eslint-disable-next-line no-undef,no-unused-vars
function FAQTabsHandler() {
  //eslint-disable-next-line no-undef,no-unused-vars
  $('.js-dynamic-faq-tabs').each(function (jsDynamicTab) {
    // eslint-disable-next-line no-undef,no-unused-vars
    let headings = $('.dynamic-faq-tabs__button', this);
    // eslint-disable-next-line no-undef,no-unused-vars
    let contents = $('.dynamic-faq-tabs__content', this);
    headings.each(function () {
      this.addEventListener('click', function () {
        let selectedTab = $(this).data('tab-index');
        headings.each(function () {
          $(this).removeClass('active');
        });
        contents.each(function () {
          $(this).removeClass('active');
        });
        $(this).addClass('active');
        contents.eq(selectedTab - 1).addClass('active');
      });
    });
    let accordions = $('.faq-accordion', this);
    accordions.each(function () {
      let accordion = $(this); // Reference to the current accordion item

      $('.faq-accordion__title', accordion).click(function () {
        let content = $(this).next('.faq-accordion__content');

        if (content.hasClass('faq-accordion__content--opened')) {
          content.removeClass('faq-accordion__content--opened');
          $(content).parent('div').removeClass('faq-accordion__item--opened');
        } else {
          // Close all other opened contents in the same accordion
          $('.faq-accordion__content--opened', accordion).removeClass(
            'faq-accordion__content--opened'
          );
          $('.faq-accordion__item', accordion).removeClass(
            'faq-accordion__item--opened'
          );
          content.addClass('faq-accordion__content--opened');
          $(content).parent('div').addClass('faq-accordion__item--opened');
        }
      });
    });
  });
}
