document.addEventListener('DOMContentLoaded', () => {
  teamSliderInit();
});
// eslint-disable-next-line no-unused-vars,no-undef
const teamSliderInit = () => {
  // eslint-disable-next-line no-unused-vars,no-undef
  const contentSwiper = new Swiper('.fwp-slider__content', {
    allowTouchMove: false, // Disable swipe
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: true,
  });
  // eslint-disable-next-line no-undef,no-unused-vars
  const imagesSwiper = new Swiper('.fwp-slider__images-inner', {
    allowTouchMove: false, // Disable swipe
    slidesPerView: 2.8,
    loop: true,
    thumbs: {
      swiper: contentSwiper,
    },
    navigation: {
      nextEl: '.team-widget__arrows .arrow-right',
      prevEl: '.team-widget__arrows .arrow-left',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2.8,
      },
    },
  });
};
