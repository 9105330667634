import $ from 'jquery';

$(document).ready(function () {
  MobileTabSelector();
});

function MobileTabSelector() {
  // Iterate over each element with the class 'js-mob-tab-selector'
  $('.js-mob-tab-selector').each(function () {
    // Get the <select> element within the current '.js-mob-tab-selector' container
    let selector = $('select', $(this));

    // Get the value of 'data-tabs-id' attribute from the <select> element
    let tabsId = selector.data('tabs-id');
    // Select all tab headings (buttons) within the tab container specified by 'tabsId'
    let tabsHeadings = $(
      '#' +
        tabsId +
        '> .elementor-widget-container > .e-n-tabs > .e-n-tabs-heading > button'
    );

    // Select all tab content items (divs) within the tab container specified by 'tabsId'
    let tabsItems = $(
      '#' +
        tabsId +
        '> .elementor-widget-container > .e-n-tabs > .e-n-tabs-content > div'
    );

    // Generate the options for the <select> element based on tab headings
    generateOptions();

    // Attach an event handler to change the active tab based on the <select> value
    changeActiveTab();

    // Function to generate <option> elements for the <select> dropdown
    function generateOptions() {
      let i = 0;
      // Iterate over each tab heading
      tabsHeadings.each(function () {
        // Create a new <option> element
        let tabOption = document.createElement('option');

        // Set the 'data-id' attribute of the <option> to the ID of the corresponding tab content
        tabOption.dataset.id = tabsItems[i].id;

        // Set the text of the <option> to the text of the corresponding tab heading
        tabOption.textContent = $(tabsHeadings.eq(i)).find('span').text();

        // Append the newly created <option> to the <select> element
        selector.append(tabOption);

        // Increment the index for the next tab
        i++;
      });
    }

    // Function to handle the change event of the <select> element
    function changeActiveTab() {
      let selectedId;
      // Attach a 'change' event listener to the <select> element
      selector.change(function () {
        // Get the 'data-id' of the selected <option>
        selectedId = selector.find('option:selected').data('id');

        // Iterate over each tab content item
        tabsItems.each(function () {
          // Check if the current tab is not the selected tab and is active
          if (this.id !== selectedId && this.classList.contains('e-active')) {
            // Remove the 'e-active' class from the inactive tab
            this.classList.remove('e-active');
          } else if (
            this.id == selectedId &&
            !this.classList.contains('e-active')
          ) {
            // Add the 'e-active' class to the selected tab
            this.classList.add('e-active');
          }
        });
      });
    }
  });
}
