// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/elementor/dynamic-faq-tabs/index.js'; import '../blocks/elementor/dynamic-product-tabs/index.js'; import '../blocks/elementor/example-widget/index.js'; import '../blocks/elementor/faq-models/index.js'; import '../blocks/elementor/mobile-tab-selector/index.js'; import '../blocks/elementor/on-demand-webinars/index.js'; import '../blocks/elementor/team-slider-widget/index.js'; import '../blocks/elementor/timeline-slider/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';
/*global ajax_object*/

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // Select all images inside the testimonials-slider block
  $('.testimonials-slider img').each(function () {
    // Remove the 'loading' attribute from each image
    $(this).removeAttr('loading');
  });
  if ($(window).width() < 768) {
    $('.elementor-tab-title:first-child')
      .removeClass('elementor-active')
      .attr('aria-expanded', 'false');
    $('.elementor-tab-content:first-child')
      .slideUp()
      .attr('aria-hidden', 'true');
  }
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.card-resource').on('mouseenter mouseleave', function () {
    $(this).find('.card-text').toggle(300);
  });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });
  $(window).on('load', function () {
    // initScrollCarousel();
  });
  scrollTabs();
  toggleFixedChat();
  openHeaderMobileMenu();
  hideHeaderTopBar();
  toogleSearchInput();
  resizeVideo();
  // setInterval(setSliderArrowMargins, 1000);
  highlightHeaderMenuItems();
});

// eslint-disable-next-line no-unused-vars
function initScrollCarousel() {
  let carousel = $('.scroll-carousel .swiper');
  if (carousel.length > 0) {
    carousel.each(function () {
      const instance = $(this).data('swiper');
      if (instance) {
        instance.mousewheel.enable();
        instance.params.freeMode.enabled = true;
        instance.update();
      } else {
        console.warn('No Swiper instance found for this element.');
      }
    });
  }
}

// eslint-disable-next-line no-unused-vars
function highlightHeaderMenuItems() {
  let menu = $('.desktop-header-menu');
  if (menu.length > 0) {
    let items = $('.e-n-menu-heading > .e-n-menu-item', $(menu));
    items.each(function () {
      let item = $(this);
      let content = $('.e-n-menu-content > .e-con', this);
      content.on('mouseenter mouseleave', function () {
        $(item).toggleClass('title-active');
      });
      let links = $('a', item);
      // console.log(links);
      // console.log(window.location.pathname);
      links.each(function () {
        let linkHref = $(this).attr('href');
        if (linkHref === window.location.pathname) {
          $(this).addClass('menu-active-link');
          item.addClass('menu-active-item');
        }
      });
    });
  }
}

// Function to set slider arrow margins
// function setSliderArrowMargins() {
//   let sliders = $('.products-slider');
//   if (sliders.length) {
//     // Use .length to check if sliders exist
//     sliders.each(function () {
//       let activeSlide = $('.swiper-slide-active img', this);
//       let slideHeight = activeSlide.height(); // Use height for vertical margin
//       let controls = $('.elementor-swiper-button', this);
//       controls.each(function () {
//         $(this).attr('style', function (i, style) {
//           return (
//             (style || '') + 'top: ' + (slideHeight + 50) + 'px !important;'
//           );
//         });
//       });
//     });
//   }
// }

// eslint-disable-next-line no-unused-vars
function openTabWithAnchor() {
  setTimeout(function () {
    const tabsAccordionToggleTitles = document.querySelectorAll(
      '.e-n-accordion-item-title, .e-n-tab-title, .elementor-tab-title'
    );

    const clickTitleWithAnchor = (anchor) => {
      tabsAccordionToggleTitles.forEach((title) => {
        if (
          title.querySelector(`#${anchor}`) != null ||
          title.id === anchor ||
          (title.closest('details') && title.closest('details').id === anchor)
        ) {
          if (
            title.getAttribute('aria-expanded') !== 'true' &&
            !title.classList.contains('elementor-active')
          )
            title.click();
        }
      });
    };

    document.addEventListener('click', (event) => {
      if (
        event.target.closest('a') &&
        event.target.closest('a').href.includes('#')
      ) {
        const anchor = extractAnchor(event.target.closest('a').href);
        if (anchor && isAnchorInTitles(anchor, tabsAccordionToggleTitles)) {
          event.preventDefault();
          clickTitleWithAnchor(anchor);
        }
      }
    });

    const currentAnchor = extractAnchor(window.location.href);
    if (currentAnchor) {
      clickTitleWithAnchor(currentAnchor);
    }

    function extractAnchor(url) {
      const match = url.match(/#([^?]+)/);
      return match ? match[1] : null;
    }

    function isAnchorInTitles(anchor, titles) {
      return Array.from(titles).some((title) => {
        return (
          title.querySelector(`#${anchor}`) !== null ||
          title.id === anchor ||
          (title.closest('details') && title.closest('details').id === anchor)
        );
      });
    }
  }, 0);
}

function scrollTabs() {
  let tabs = document.querySelectorAll(
    '.elementor-widget-mobile_tab_selector + .elementor-widget-n-tabs'
  );
  if (tabs) {
    tabs.forEach(function (tab) {
      let headings = tab.querySelector('.e-n-tabs-heading');
      let titles = tab.querySelectorAll('.e-n-tabs-heading > .e-n-tab-title');
      let offset = headings.getBoundingClientRect().top + window.scrollY;
      if (!tab.classList.contains('sticky-tabs')) {
        tab.classList.add('sticky-tabs');
      }
      if (window.scrollY - 180 >= offset) {
        tab.classList.add('in-viewport');
      } else {
        tab.classList.remove('in-viewport');
      }
      window.addEventListener('scroll', function () {
        if (window.scrollY >= offset) {
          tab.classList.add('in-viewport');
        } else {
          tab.classList.remove('in-viewport');
        }
      });
      titles.forEach(function (title) {
        title.addEventListener('click', function () {
          let tabPosition =
            tab.getBoundingClientRect().top + window.pageYOffset - 200;
          window.scrollTo(0, tabPosition);
        });
      });
    });
  }
}

function openHeaderMobileMenu() {
  let body = $('body');
  let menuButton = $('#header-mobile-button');
  let menu = $('#header-mob-menu');
  menuButton.on('click', function () {
    menuButton.toggleClass('header-menu-opened');
    menu.toggleClass('header-menu-opened');
    body.toggleClass('header-menu-opened');
  });
}

function hideHeaderTopBar() {
  let topbar = $('#main-header-topbar');
  let content = $('#main-header-content');
  let button = $('#main-header-closebutton');
  button.on('click', function () {
    topbar.addClass('topbar-permahidden');
    content.addClass('topbar-permahidden');
  });
  if (!topbar.hasClass('topbar-permahidden') && $(window).width() < 1025) {
    $(window).scroll(function () {
      if ($(window).scrollTop() > 0) {
        topbar.hide();
      } else {
        topbar.show();
      }
    });
  }
}

function toggleFixedChat() {
  let chat = $('#fixed-chat');
  if (chat) {
    let form = $('#fixed-chat-item');
    let buttons = $('.fixed-chat-close');
    buttons.each(function () {
      this.addEventListener('click', function () {
        event.preventDefault();
        $(form)[0].classList.toggle('form-active');
      });
    });
  }
}

function toogleSearchInput() {
  let search = $('.search-group');
  search.each(function () {
    let button = $('.search-group-button', this);
    let input = $('.search-group-input', this);
    button.on('click', function () {
      event.preventDefault();
      input.toggle();
    });
  });
}

function webinarsLoopHandler() {
  const carouselDots = $('#webinars-loop-posts');
  const imageCarousel = jQuery('#webinars-loop .swiper'),
    swiperInstance = imageCarousel.data('swiper');
  let dotPosts = $('.e-loop-item', carouselDots);
  if (
    carouselDots &&
    imageCarousel &&
    dotPosts &&
    carouselDots.length > 0 &&
    imageCarousel.length > 0 &&
    dotPosts.length > 0
  ) {
    let i = 0;
    dotPosts[0].classList.add('is-active');
    dotPosts.each(function () {
      $(this).attr('dot-index', i + 1);
      this.addEventListener('click', function () {
        swiperInstance.slideTo(parseInt($(this).attr('dot-index')));
        dotPosts.each(function () {
          if (this.classList.contains('is-active')) {
            this.classList.remove('is-active');
          }
        });
        this.classList.add('is-active');
      });
      i++;
    });
    const controls = $('.elementor-swiper-button', '#webinars-loop');
    controls.each(function () {
      this.addEventListener('click', function () {
        let slideIndex = $('.swiper-slide-active', imageCarousel).attr(
          'data-swiper-slide-index'
        );
        let dotIndex;
        dotPosts.each(function () {
          dotIndex = $(this).eq(0).attr('dot-index');
          if (
            this.classList.contains('is-active') &&
            parseInt(dotIndex) !== parseInt(slideIndex) + 1
          ) {
            this.classList.remove('is-active');
          } else if (parseInt(dotIndex) == parseInt(slideIndex) + 1) {
            this.classList.add('is-active');
          }
        });
      });
    });
  }
}

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  const emptyCartMessage = $('.elementor-menu-cart__main');
  if (emptyCartMessage.length) {
    // Append a link to the emptyCartMessage
    emptyCartMessage.append(
      '<a class="submenu__card-shop-btn" href="/e-store"> visit the e-store</a>'
    );
  }
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
  webinarsLoopHandler();
  openTabWithAnchor();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  // setSliderArrowMargins();
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
document.addEventListener('DOMContentLoaded', () => {
  FAQTabsAjax();
  FAQModelsAjax();
  FAQTabsHandler();
  toggleWebinarsVisibility();
  WebinarAjax();
  createPagination(12);
});
let isLoading = false; // Flag to track if an AJAX request is in progress

$('.demand-webinars__search').on('click', function () {
  $(this).toggleClass('search-active');
  $('.webinar-btns__list').toggleClass('btns-active');
});

$('.js-tax-btn ').on('click', function () {
  // Remove the active class from all buttons
  $('.js-tax-btn ').removeClass('js-tax-btn__active');

  // Add the active class to the clicked button
  $(this).addClass('js-tax-btn__active');
});

function createPagination(postsPerPage) {
  const $paginationContainer = $('.pagination-container');
  let $current_page = $paginationContainer.data('current-page');
  console.log($current_page);
  const maxPages = $paginationContainer.data('max-pages'); // Get the total number of pages from data attribute
  const totalPosts = maxPages * postsPerPage; // Calculate total posts based on max pages and posts per page

  function renderPagination(currentPage, totalPages) {
    const $paginationContainer = $('.pagination-container');
    $paginationContainer.empty(); // Clear the pagination container

    // Maximum number of page links to display
    const maxLinks = maxPages;

    // Calculate the start and end pages for pagination
    const startPage = Math.max(1, currentPage - Math.floor(maxLinks / 2));
    const endPage = Math.min(totalPages, startPage + maxLinks - 1);

    // Create "First" page link if needed
    if (startPage > 1) {
      const $firstPage = $('<button></button>')
        .addClass('page-btn')
        .text('1')
        .data('page', 1); // Link to the first page
      $paginationContainer.append($firstPage);

      // Add ellipsis if skipped pages exist
      if (startPage > 2) {
        const $dots = $('<span></span>').text('...');
        $paginationContainer.append($dots);
      }
    }

    // Loop to create numbered page links
    for (let i = startPage; i <= endPage; i++) {
      const $pageButton = $('<button></button>')
        .addClass('page-btn')
        .text(i)
        .data('page', i); // Set data for the page
      $paginationContainer.append($pageButton);
    }

    // Create "Last" page link if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        const $dots = $('<span></span>').text('...');
        $paginationContainer.append($dots); // Add ellipsis if skipped pages exist
      }

      const $lastPage = $('<button></button>')
        .addClass('page-btn')
        .text(totalPages)
        .data('page', totalPages); // Link to the last page
      $paginationContainer.append($lastPage);
    }
  }

  // Initialize Pagination with total pages
  const totalPages = Math.ceil(totalPosts / postsPerPage); // Calculate total pages
  renderPagination(1, totalPages); // Start with page 1

  const paginationContainer = document.querySelector('.pagination-container');
  if (paginationContainer) {
    // Ensure the element exists before accessing properties
    const currentPage = parseInt(
      paginationContainer.getAttribute('data-current-page'),
      10
    );

    const buttons = paginationContainer.querySelectorAll('.page-btn');
    // Remove 'active' class from all buttons
    buttons.forEach((button, index) => {
      button.classList.toggle('active', index + 1 === currentPage);
    });
  }

  // Event delegation for pagination button clicks
  $(document).on('click', '.prev-btn, .next-btn, .page-btn', function () {
    const page = $(this).data('page');
    if (page >= 1 && page <= totalPages) {
      renderPagination(page, totalPages); // Render pagination for the new page
      fetchWebinars(page); // Trigger AJAX when changing pages
      const $section = $('.demand-webinars__tax-list');
      if ($section.length) {
        const sectionOffset = $section.offset().top;
        const scrollToPosition = sectionOffset - $(window).height() * 0.2; // 20% offset
        $('html, body').animate({ scrollTop: scrollToPosition }, 500); // Smooth scroll
      }
    }
  });
}

function fetchWebinars(currentPage = 1) {
  // Check if there's already an ongoing AJAX request
  if (isLoading) return;

  isLoading = true; // Set the flag to true to indicate an ongoing request

  const selectedTerms =
    $('.js-webinar-tax__value.webinar_selected').data('term') || '';
  const selectedTax =
    $('.js-webinar-tax__value.webinar_selected').data('tax') || '';

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'GET',
    data: {
      action: 'filter_webinars_by_taxonomy',
      taxonomy_terms: selectedTerms,
      taxonomy_tax: selectedTax,
      current_page: currentPage,
    },
    success: function (data) {
      $('.demand-webinars__posts-list').html(data.html); // Update posts list
      if (data.max_pages) {
        // Update pagination with new total pages from the server
        if ($('.pagination-container').length) {
          $('.pagination-container').data('max-pages', data.max_pages);
          createPagination(12); // Re-render pagination with updated total pages
        }
      }
    },
    complete: function () {
      isLoading = false; // Reset the flag once the AJAX request is complete
    },
  });
}

function WebinarAjax() {
  $('.js-webinar-tax__value').on('click', function () {
    // Remove 'webinar_selected' class from all items
    $('.js-webinar-tax__value').removeClass('webinar_selected');
    // Toggle the class only on the clicked item
    $(this).toggleClass('webinar_selected');
    // Trigger fetching webinars on taxonomy selection
    fetchWebinars(1); // Reset to page 1 on filter change
  });
}

function toggleWebinarsVisibility() {
  let swiper_slider_items = $('.swiper-slide');
  let webinars_no_posts = $('.webinars_no_posts');

  if (swiper_slider_items.length >= 1) {
    webinars_no_posts.animate({ opacity: 0 }, 300, function () {
      $(this).css('visibility', 'hidden');
    });
  } else {
    webinars_no_posts.css('visibility', 'visible').animate({ opacity: 1 }, 300); // Show the element
  }
}

function FAQTabsHandler() {
  let tabs = document.querySelectorAll(
    '.elementor-widget-mobile_tab_selector + .elementor-widget-n-tabs'
  );

  tabs.forEach(function (tab) {
    let headings = tab.querySelector('.e-n-tabs-heading');
    let btn_titles = tab.querySelectorAll('.dynamic-faq-tabs__button');
    let btn_search = tab.querySelectorAll('.faqs-search-input__btn');
    let offset = headings.getBoundingClientRect().top + window.scrollY;

    if (!tab.classList.contains('sticky-tabs')) {
      tab.classList.add('sticky-tabs');
    }
    if (window.scrollY - 180 >= offset) {
      tab.classList.add('in-viewport');
    } else {
      tab.classList.remove('in-viewport');
    }
    window.addEventListener('scroll', function () {
      if (window.scrollY >= offset) {
        tab.classList.add('in-viewport');
      } else {
        tab.classList.remove('in-viewport');
      }
    });
    if (window.innerWidth > 1024) {
      btn_titles.forEach(function (btn_title) {
        btn_title.addEventListener('click', function () {
          let tabPosition =
            tab.getBoundingClientRect().top + window.pageYOffset - 200;
          window.scrollTo(0, tabPosition);
        });
      });

      btn_search.forEach(function (btn_search) {
        btn_search.addEventListener('click', function () {
          let tabPosition =
            tab.getBoundingClientRect().top + window.pageYOffset - 200;
          window.scrollTo(0, tabPosition);
        });
      });
    }
    if (window.innerWidth < 1024) {
      btn_titles.forEach(function (btn_title) {
        btn_title.addEventListener('click', function () {
          // Calculate the position relative to the top of the document
          let tabPosition = tab.offsetHeight;
          window.scrollTo(0, tabPosition);
        });
      });
    }
  }); //eslint-disable-next-line no-undef,no-unused-vars
  $('.js-dynamic-faq-tabs').each(function (jsDynamicTab) {
    // eslint-disable-next-line no-undef,no-unused-vars
    let headings = $('.dynamic-faq-tabs__button', this);
    // eslint-disable-next-line no-undef,no-unused-vars
    let contents = $('.dynamic-faq-tabs__content', this);
    headings.each(function () {
      this.addEventListener('click', function () {
        let selectedTab = $(this).data('tab-index');
        headings.each(function () {
          $(this).removeClass('active');
        });
        contents.each(function () {
          $(this).removeClass('active');
        });
        $(this).addClass('active');
        contents.eq(selectedTab - 1).addClass('active');
      });
    });
    // let accordions = $('.faq-accordion', this);
    // accordions.each(function () {
    //   let accordion = $(this); // Reference to the current accordion item
    //
    //   $('.faq-accordion__title', accordion).click(function () {
    //     console.log('dasdasd');
    //     let content = $(this).next('.faq-accordion__content');
    //
    //     if (content.hasClass('faq-accordion__content--opened')) {
    //       content.removeClass('faq-accordion__content--opened');
    //       $(content).parent('div').removeClass('faq-accordion__item--opened');
    //     } else {
    //       // Close all other opened contents in the same accordion
    //       $('.faq-accordion__content--opened', accordion).removeClass(
    //         'faq-accordion__content--opened'
    //       );
    //       $('.faq-accordion__item', accordion).removeClass(
    //         'faq-accordion__item--opened'
    //       );
    //       content.addClass('faq-accordion__content--opened');
    //       $(content).parent('div').addClass('faq-accordion__item--opened');
    //     }
    //   });
    // });
  });
}

function FAQModelsAjax() {
  if ($('.js-dynamic-faq-tabs').length) {
    $('.js-dynamic-faq-tabs').each(function () {
      let jsDynamicTab = $(this);
      let searchCPT = jsDynamicTab.data('type');
      let searchCat = jsDynamicTab.data('cat');
      let searchvalue = true;
      jsDynamicTab.off('click', '.models_faqs-search-input__btn'); // Remove old bindings
      jsDynamicTab.on('click', '.models_faqs-search-input__btn', function () {
        let searchTerm = '';
        $('.faqs-search-input').each(function () {
          let searchIndex = $(this).data('search-index');
          // If you want to compare the value of the input
          if (searchIndex == searchCat) {
            searchTerm = $(this).val();
          }
        });
        if (searchTerm == '') {
          searchvalue = false;
        }
        $.ajax({
          url: ajax_object.ajax_url,
          type: 'POST',
          data: {
            action: 'handle_search_faq_request_models',
            faq_cpt: searchCPT,
            faq_category: searchCat,
            faq_search: searchTerm,
            faq_search_val: searchvalue,
          },
          success: function (data) {
            if (data.html) {
              jsDynamicTab.empty();
              jsDynamicTab.append(data.html);
              FAQTabsHandler();
              // Reinitialize everything after content reload
              reExecuteTabJS(jsDynamicTab); // Re-initialize tabs and accordions
              FAQTabsAjax(); // Reinitialize AJAX functionality for new content
            } else {
              console.log('No HTML returned in response');
            }
          },
        });
      });
    });
  }

  function reExecuteTabJS(tab) {
    // Get the headings and contents specific to this tab
    let headings = $('.dynamic-faq-tabs__button', tab);
    let contents = $('.dynamic-faq-tabs__content', tab);

    headings.each(function (index) {
      $(this).on('click', function () {
        headings.removeClass('active');
        contents.removeClass('active');
        $(this).addClass('active'); // Add active class to the clicked heading
        contents.eq(index).addClass('active'); // Show the corresponding content
      });
    });

    // let accordions = $('.faq-accordion', tab);
    // accordions.each(function () {
    //   let accordion = $(this); // Reference to the current accordion item
    //   $('.faq-accordion__title', accordion).click(function () {
    //     console.log('dasdasd');
    //     let content = $(this).next('.faq-accordion__content');
    //     if (content.hasClass('faq-accordion__content--opened')) {
    //       content.removeClass('faq-accordion__content--opened');
    //       $(content).parent('div').removeClass('faq-accordion__item--opened');
    //     } else {
    //       // Close all other opened contents in the same accordion
    //       $('.faq-accordion__content--opened', accordion).removeClass(
    //         'faq-accordion__content--opened'
    //       );
    //       $('.faq-accordion__item', accordion).removeClass(
    //         'faq-accordion__item--opened'
    //       );
    //       content.addClass('faq-accordion__content--opened');
    //       $(content).parent('div').addClass('faq-accordion__item--opened');
    //     }
    //   });
    // });
  }
}

$(document).on('click', '.faq-accordion__title', function () {
  let accordionItem = $(this).closest('.faq-accordion__item'); // Target the closest accordion item
  let content = accordionItem.find('.faq-accordion__content'); // Find the content within the same item

  if (content.hasClass('faq-accordion__content--opened')) {
    // Close the currently opened content
    content.removeClass('faq-accordion__content--opened');
    accordionItem.removeClass('faq-accordion__item--opened');
  } else {
    // Close all other opened contents in the document
    $('.faq-accordion__content--opened').removeClass(
      'faq-accordion__content--opened'
    );
    $('.faq-accordion__item--opened').removeClass(
      'faq-accordion__item--opened'
    );

    // Open the clicked content
    content.addClass('faq-accordion__content--opened');
    accordionItem.addClass('faq-accordion__item--opened');
  }
});

function FAQTabsAjax() {
  if ($('.js-dynamic-faq-tabs').length) {
    $('.js-dynamic-faq-tabs').each(function () {
      let jsDynamicTab = $(this);
      let searchCPT = jsDynamicTab.data('type');
      let searchCat = jsDynamicTab.data('cat');
      let searchvalue = true;
      jsDynamicTab.off('click', '.faqs-search-input__btn'); // Remove old bindings
      jsDynamicTab.on('click', '.faqs-search-input__btn', function () {
        console.log('dasd');
        let searchTerm = '';

        $('.faqs-search-input').each(function () {
          let searchIndex = $(this).data('search-index');
          // If you want to compare the value of the input
          if (searchIndex == searchCat) {
            searchTerm = $(this).val();
          }
        });
        if (searchTerm == '') {
          searchvalue = false;
        }
        $.ajax({
          url: ajax_object.ajax_url,
          type: 'POST',
          data: {
            action: 'handle_search_faq_request',
            faq_cpt: searchCPT,
            faq_category: searchCat,
            faq_search: searchTerm,
            faq_search_val: searchvalue,
          },
          success: function (data) {
            if (data.html) {
              jsDynamicTab.empty();
              jsDynamicTab.append(data.html);
              // Reinitialize everything after content reload
              reExecuteTabJS(jsDynamicTab); // Re-initialize tabs and accordions
              FAQTabsAjax(); // Reinitialize AJAX functionality for new content
              FAQTabsHandler();
            } else {
              console.log('No HTML returned in response');
            }
          },
        });
      });
    });
  }

  function reExecuteTabJS(tab) {
    // Get the headings and contents specific to this tab
    let headings = $('.dynamic-faq-tabs__button', tab);
    let contents = $('.dynamic-faq-tabs__content', tab);

    headings.each(function (index) {
      $(this).on('click', function () {
        headings.removeClass('active');
        contents.removeClass('active');
        $(this).addClass('active'); // Add active class to the clicked heading
        contents.eq(index).addClass('active'); // Show the corresponding content
      });
    });

    // let accordions = $('.faq-accordion', tab);
    // accordions.each(function () {
    //   let accordion = $(this); // Reference to the current accordion item
    //
    //   $('.faq-accordion__title', accordion).click(function () {
    //     console.log('dasdasd');
    //     let content = $(this).next('.faq-accordion__content');
    //     if (content.hasClass('faq-accordion__content--opened')) {
    //       content.removeClass('faq-accordion__content--opened');
    //       $(content).parent('div').removeClass('faq-accordion__item--opened');
    //     } else {
    //       // Close all other opened contents in the same accordion
    //       $('.faq-accordion__content--opened', accordion).removeClass(
    //         'faq-accordion__content--opened'
    //       );
    //       $('.faq-accordion__item', accordion).removeClass(
    //         'faq-accordion__item--opened'
    //       );
    //       content.addClass('faq-accordion__content--opened');
    //       $(content).parent('div').addClass('faq-accordion__item--opened');
    //     }
    //   });
    // });
  }
}
