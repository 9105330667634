document.addEventListener('DOMContentLoaded', () => {
  dynamicProductTabsInit();
  tabsHandler();
});

function dynamicProductTabsInit() {
  $('.js-dynamic-product-tabs').each(function () {
    // eslint-disable-next-line no-undef,no-unused-vars
    const DynImageSliderInit = () => {
      // eslint-disable-next-line no-undef,no-unused-vars
      const imagesSwiper = new Swiper('.dynamic-product-tabs__slider .swiper', {
        allowTouchMove: true, // Disable swipe
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: '.dynamic-product-tabs__arrows .arrow-right',
          prevEl: '.dynamic-product-tabs__arrows .arrow-left',
        },
      });
    };
    DynImageSliderInit();
  });
}

// eslint-disable-next-line no-undef,no-unused-vars
function tabsHandler() {
  $('.js-dynamic-product-tabs').each(function () {
    // eslint-disable-next-line no-undef,no-unused-vars
    let headings = $('.dynamic-product-tabs__button', this);
    // eslint-disable-next-line no-undef,no-unused-vars
    let contents = $('.dynamic-product-tabs__content', this);
    headings.each(function () {
      this.addEventListener('click', function () {
        let selectedTab = $(this).data('tab-index');
        headings.each(function () {
          $(this).removeClass('active');
        });
        contents.each(function () {
          $(this).removeClass('active');
        });
        $(this).addClass('active');
        contents.eq(selectedTab - 1).addClass('active');
      });
    });
  });
}
