import $ from 'jquery';

$(document).ready(function () {
  TeamSliderWidget();
});

function TeamSliderWidget() {
  $('.js-team-slider-widget').each(function () {
    $('.description-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      infinite: true,
      asNavFor: '.image-slider',
    });
    $('.image-slider').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      asNavFor: '.description-slider',
      dots: false,
      arrows: false,
      centerMode: false,
      focusOnSelect: false,
    });
  });
}
