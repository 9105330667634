document.addEventListener('DOMContentLoaded', () => {
  dynamicProductTabsInit();
  tabsHandler();
});

function dynamicProductTabsInit() {
  $('.js-dynamic-product-tabs').each(function () {
    $(this)
      .find('.dynamic-product-tabs__slider')
      .each(function (index) {
        const swiperContainer = $(this).find('.swiper')[0];
        const prevButton = $(this).find(`.arrow-left-${index + 1}`)[0];
        const nextButton = $(this).find(`.arrow-right-${index + 1}`)[0];

        if (swiperContainer && prevButton && nextButton) {
          // eslint-disable-next-line no-undef,no-unused-vars
          new Swiper(swiperContainer, {
            allowTouchMove: true,
            slidesPerView: 1,
            loop: true,
            navigation: {
              nextEl: nextButton,
              prevEl: prevButton,
            },
          });
        } else {
          console.warn('Swiper elements missing for index:', index + 1);
        }
      });
  });
}

// eslint-disable-next-line no-undef,no-unused-vars
function tabsHandler() {
  $('.js-dynamic-product-tabs').each(function () {
    let headings = $('.dynamic-product-tabs__button', this);
    let contents = $('.dynamic-product-tabs__content', this);

    headings.each(function () {
      this.addEventListener('click', function () {
        let selectedTab = $(this).data('tab-index');

        headings.each(function () {
          $(this).removeClass('active');
        });
        contents.each(function () {
          $(this).removeClass('active');
        });

        $(this).addClass('active');
        let activeContent = contents.eq(selectedTab - 1);
        activeContent.addClass('active');

        // Reinitialize Swiper inside the active tab
        setTimeout(() => {
          activeContent.find('.swiper').each(function () {
            // eslint-disable-next-line no-undef,no-unused-vars
            new Swiper(this, {
              allowTouchMove: true,
              slidesPerView: 1,
              loop: true,
              navigation: {
                nextEl: '.dynamic-product-tabs__arrows .arrow-right',
                prevEl: '.dynamic-product-tabs__arrows .arrow-left',
              },
            });
          });
        }, 100); // Small timeout to ensure the tab is visible before initializing Swiper
      });
    });
  });
}
